import axios from "axios";
import { useloggedUserInfo } from "./stores/NavUserInfo";
// Our APIGATEWAY URL
const baseDomain = process.env.VUE_APP_API_URL; 
const baseURL = `${baseDomain}`;
const baseCommonURL = process.env.VUE_APP_COMMON_API;
const baseURLCommon = `${baseCommonURL}`;
const baseTimerURL = process.env.VUE_APP_TIMER_API
const baseURLTimer = `${baseTimerURL}`;
const baseProjectURL = process.env.VUE_APP_PROJECT_API;
const baseURLProject = `${baseProjectURL}`;
const instance = axios.create({
  baseURL,
});
const instanceCommon = axios.create({
    baseURL:baseURLCommon,
  });
  const instanceTimer = axios.create({
    baseURL:baseURLTimer,
  });
const instanceProject = axios.create({
    baseURL:baseURLProject,
  });
  instanceTimer.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json";
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
instance.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] =config.url=='/ImportContactsPost' || config.url=='/AddProduct'?"multipart/form-data":  "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceCommon.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
instanceProject.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default {instance,instanceCommon,instanceProject,instanceTimer};
