<template lang="en">
<div class="col-12 p-0">
    <div class="col-md-12 p-0 break-section" v-if="!breakModule">
        <div class="col-md-12 mt-3">
            <h5 class="h5 heading-border">
                <span>Breaks</span>
            </h5>
        </div>
        <div class="col-md-12 mt-3">
            <div class="">
                <dynamic-form lang="en" :buttons="buttons" ref="form" :schema="FormSchema" v-on:OnSubmit="submit()">
                    <template v-slot:tgslot-button="{ data }">
                        <div class="col-md-12 mt-4">
                            <div class="col-md-6 Break-Canves d-none float-left">
                                <div id="BreakTimerCanves" class="DivTimer working-timer">
                                    00 : 00 : 00
                                </div>
                            </div>
                            <div class="col-md-12 p-0">
                                <div class="start-break" v-on:click="submitForm">
                                    <a class="start-break-clr py-3 mt-2 font-18 d-block text-uppercase text-center text-white startbreak" id="btnBreakStart" title="" style="cursor: pointer" data-toggle-tooltip="tooltip" data-original-title="Start Break" type="submit">
                                        <i class="fa fa-coffee pr-2"></i><span>Start Break</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                </dynamic-form>
            </div>
        </div>

    </div>

    <div class="my-popups" v-else>
    <div class="modal start-timer cust_timer d-block">
    <div class="modal-dialog modal-dialog-centered" >
        <div class="modal-content" id="DivTimerContent">
            <div class="modal-header">
                <h4 class="modal-title">Choose Break</h4>
                <button type="button" class="close" style="background: none;color: #fff !important;" @click="$emit('close',{breakTimer:false,modalClose:false})"><em aria-hidden="true" class="fa fa-times"></em></button>
            </div>
            <div class="modal-body" id="divModalTimetrack">
                <dynamic-form lang="en" :buttons="buttons" ref="form" :schema="FormSchema" v-on:OnSubmit="submit()">
                    <!-- <template v-slot:tgslot-button="{ data }">
                                
                    </template> -->
                </dynamic-form>
                <div class="start-break col-12 float-left p-0" v-on:click="submitForm">
                                    <a class="btn btn-primary text-white btn-lg starttimer w-100" id="btnBreakStart" title="" style="cursor: pointer" data-toggle-tooltip="tooltip" data-original-title="Start Break" type="submit">
                                        <i class="fa fa-coffee pr-2"></i><span>Start</span>
                                    </a>

                        </div>
            </div>


        </div>
    </div>
    </div>
</div>

    <div class="overlay" id="notifymessage" style="" v-if="state.error">
        <div class="alert alert-dismissible fade show msg mw-90 alert-danger" role="alert">
            <span>{{state.error  }}</span>
            <button type="button" class="close p-1" id="closenotifymessage" style="background:none !important;" @click="state.error = ''">
                <span aria-hidden="true"><i class="fa fa-times text-secondary"></i></span>
            </button>
        </div>
    </div>
</div>
</template>

<style>

#divModalTimetrack #button label{display: none;}
#divModalTimetrack #button .form-group{margin:0px;}
</style>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    onServerPrefetch
} from '@vue/composition-api'
import Multiselect from 'vue-multiselect'
import {
    userTimerInfo
} from "../../stores/TimeTracker";
import {
    storeToRefs
} from "pinia";
import moment from "moment"
import {
    timeToSeconds
} from "@/assets/common/vue-common.js"
export default defineComponent({
    components: {
        Multiselect
    },
    props: {
        userId: {
            type: Number,
            default: "my title",
        },
        timerModule: {
            type: Boolean
        },
        trackDataPouse:{
          type:Object
        }
    },
    data() {
        return {
            FormSchema: [{
                layoutType: "single",
                Data: [{
                        astype: "MultiSelectField",
                        label: this.$t('AddBreak'),
                        name: "breakTime",
                        value: "",
                        placeholder: "",
                        config: {
                            options: this.getBreakTimeList,
                            showAddIcon: false,
                        },
                        // validationRules: "required",
                        validationRules: {
                            "required": true
                        },

                    },
                    {
                        astype: "SlotField",
                        label: '',
                        name: "button",
                        value: "",
                        placeholder: "",
                        config: {
                            options: [],
                            showAddIcon: false,
                            onChange: this.onChangeDropdown
                        },
                        validationRules: "",
                    },
                ]
            }],
            buttons: false,
        }
    },
    setup(props, context) {
        //if true open components from header timer else open from add timetrack module
        const breakModule = context.attrs.timerModule
        const trackDataProps = context.attrs.trackDataPouse
        const store = userTimerInfo();
        const formResponse = ref()
        const duration = ref()
        const trackerData = reactive({
            projectId: null,
            trackDescription: "",
            taskId: null,
            isBillable: false,
            format: false,
            date: null,
            trackId: 0,
            startTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            stopTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            companyId: null,
            currentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            trackAgent: "web",
            timerStatus: "start",
            CreatedBy: context.attrs.userId,
            linkedTrackId: null,
            TotalHours: 0,
            timeformat: "12",
            TagIds: null,
            isForcedUpdate: null,
            type: "Break",
            BreakDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            BreakName: null,
            BreakId: 0,
            BreakStartTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            BreakStopTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            Shift_ID: 0,
            SelectedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            Break_Shift: ""
        })

        const {
            getBreakTimeList,
            getTimerRunResponse,
            timerInfo
        } = storeToRefs(store)
        const breakTimer = reactive({
            breakTime: ''
        })
        const state = ref({
            submitted: false,
            error:''
        })
        onMounted(() => {
            store.getBreakTime();
        })
        async function submit() {

            trackerData.BreakId = formResponse.value.value
            trackerData.BreakName = formResponse.value.label
            trackerData.Shift_ID = timerInfo.value.shift_ID
            if(context.attrs.trackDataPouse){
            trackDataProps.stopTime = moment().format('YYYY-MM-DD HH:mm:ss')
            trackDataProps.totalHours = this.store.$state.timerCountInSec
            trackDataProps.timerStatus ='pause'
            trackDataProps.projectId = trackDataProps.projectId && typeof trackDataProps.projectId =="object" ? trackDataProps.projectId.projectId : trackDataProps.projectId
            trackDataProps.taskId = trackDataProps.taskId && typeof trackDataProps.taskId =="object" ? trackDataProps.taskId.value : trackDataProps.taskId
            trackDataProps.trackDescription = timerInfo.value.trackDescription
            await store.submitProjectInfo(trackDataProps);
            }
          
            await store.submitProjectInfo(trackerData);
            store.getTimerInfo();
            if (getTimerRunResponse.value?.trackId) {
                store.$state.breakTimerCountInSec = duration.value
                store.breakStartTimer()
                store.stopTimer()
                 //breakTimer for timer and breack show in header
                this.$emit("close",{"breakTimer":true,"modalClose":false})
            } else {
                state.value.error = getTimerRunResponse.value?.userMsg;
            }

        }

        return {
            breakTimer,
            state,
            getBreakTimeList,
            trackerData,
            getTimerRunResponse,
            submit,
            formResponse,
            store,
            duration,
            breakModule

        }
    },

    props: {},
    created: function () {

    },
    methods: {
        submitForm: function () {
            this.$refs.form.validateForm.validate().then((result) => {
                if (result) {
                    const time = this.store.getBreakTimeList.find((data) => data.name == this.FormSchema[0].Data[0].value.label)
                    this.duration = timeToSeconds(time.breakDuration)
                    this.formResponse = this.FormSchema[0].Data[0].value
                    this.$refs.form.onSubmit();
                }
            });
        },

    }

})
</script>
