<template>
    <div class="my-popups">
        <!-- The Modal -->
        <div class="modal time-track-slide cust_timer right fade show" id="DivTimerNew" style="display: block;"
            aria-modal="true" role="dialog">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-white" id="DivTimerContent">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title text-white">{{ $t('Header_TimeTrack') }}</h4>
                        <button id="AddManually" title="Add Manually" type="button" class="addmanually_btn"><i
                                class="fa fa-clock-o m-0 " style="font-size:20px" aria-hidden="true"></i></button>
                        <input id="AddManuallyHidden" type="hidden" value="Add Manually">
                        <button id="closeTimer" type="button" style="background: none;color: #fff !important; right: 16px;"
                            class="close" @click="$emit('close')" data-dismiss="modal"><i class="fa fa-times"
                                aria-hidden="true"></i></button>
                    </div>
                    <!-- Modal body -->
                    
                    <div class="modal-body" id="divModalTimetrack">
                        <form v-on:submit.prevent="submit" ref="form">
                            <div class="row defuse-navbar">
                                <div class="col-md-12 float-left p-0">
                                    <div class="listing">
                                        <div class="col-12 mb-2">
                                            <div class="mr-2 d-inline-block">
                                                <span class="legends_low colorboxsquare"></span>{{ $t('Working_hours') }}
                                            </div>
                                            <div class="mr-2 d-inline-block">
                                                <span class="legends_profile colorboxsquare"></span>{{ $t('Break') }}
                                            </div>
                                            <div class="mr-2 overlap-entry d-none">
                                                <span class="legends_Critical colorboxsquare"></span>{{ $t('OverlapEntry') }}
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-12 float-left">
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-success" role="progressbar"
                                                style="width:40%">

                                            </div>
                                            <div class="progress-bar progress-bar-danger" role="progressbar"
                                                style="width:40%">

                                            </div>
                                        </div>

                                        <div class="d-flex bd-highlight">
                                            <div class="p-2 flex-grow-1 bd-highlight">0h</div>
                                            <div class="p-2 bd-highlight" id="ShiftTime">12h</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 float-left">
                                        <div class="form-group mt-4">
                                            <label class="typo__label">{{ $t('SelectProject') }}</label>
                                            <multiselect @input="taskByProject" v-model="trackerData.projectId"
                                                :options="getProjectsByUser" label="projectName" track-by="projectId" :show-labels="false">
                                            </multiselect>

                                        </div>
                                    </div>
                                    <div class="col-md-12 float-left">
                                        <div class="form-group">
                                            <label>{{ $t('SelectTask') }}</label>
                                            <div class="form-group" id="ddlTaskUnassigned" style="display: block;">
                                                <multiselect v-model="trackerData.taskId" :options="getTasksFromProject"
                                                    label="firstAttribute" track-by="value" :show-labels="false">
                                                    <template slot="option" slot-scope="props">
                                                        <div class="option__desc"><span class="option__title">{{
                                                            props.option.firstAttribute }}</span> <span class="float-right"> <div class="progress"><div class="progress-bar progress-bar-success" role="progressbar"
                                                :style="'width:'+props.option.thirdAttribute+'%'">
                                            </div></div>{{ props.option.thirdAttribute }}%</span></div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 float-left">
                                        <div class="col-md-6 col-sm-6 float-left p-0">
                                            <div class="form-group inline">
                                                <label>{{ $t('Billable') }}:</label>
                                                <div class="form-group">
                                                    <asp-checkbox-toggle><label class="switch">
                                                            <input type="checkbox" id="IsbillableN"
                                                                v-model="trackerData.isBillable" name="Isbillable"
                                                                class="dynamic custom-switch"><span class="slider round">
                                                                <span class="slider-yes">Yes</span><span
                                                                    class="slider-no">No</span></span></label>
                                                    </asp-checkbox-toggle>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 float-left">
                                            <div class="form-group inline">
                                                <label>{{ $t('Hours24')}}r:</label>
                                                <div class="form-group">
                                                    <asp-checkbox-toggle><label class="switch"><input type="checkbox"
                                                                v-on:change="timeChange" v-model="trackerData.format"
                                                                id="chkHourFormatN" name="format"
                                                                class="dynamic custom-switch"><span
                                                                class="slider round"><span
                                                                    class="slider-yes">Yes</span><span
                                                                    class="slider-no">No</span></span></label></asp-checkbox-toggle>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 float-left">
                                        <label for="FormControlTextarea1">{{ $t('Description') }}</label>
                                        <div class="form-group">

                                            <textarea v-if="trackerData.projectId || trackerData.trackDescription" id="Taskdescription" name="trackDescription"
                                                v-model="trackerData.trackDescription"
                                                style="border-color: #BDBCBD; resize:none" rows="10" cols="2000"
                                                class="timertxtarea form-control" v-on:keydown="textCounter"
                                                v-on:keyup="textCounter" v-on:mouseout="textCounter"
                                                placeholder="Please Enter Description" maxlength="2000"></textarea>
                                                <textarea v-else id="Taskdescription" name="trackDescription"
                                                v-model="trackerData.trackDescription"
                                                v-validate="'required'"
                                                
                                                style="border-color: #BDBCBD; resize:none" rows="10" cols="2000"
                                                class="timertxtarea form-control is-invalid" v-on:keydown="textCounter"
                                                v-on:keyup="textCounter" v-on:mouseout="textCounter"
                                                placeholder="Please Enter Description" maxlength="2000"></textarea>
                                            <div v-if="state.submitted && errors.has('trackDescription')"
                                                class="text-danger font-weight-bold" id="lblError">{{
                                                    errors.first('trackDescription') }}</div>

                                            <small>
                                                <strong>
                                                    <span class="alert-warning px-2 mr-2"
                                                        id="txtCount">{{ state.textCountertlength }}</span>
                                                </strong>
                                                {{ $t('CharactersLeft') }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-md-12 float-left">
                                        <div id="TimerCanves1" class="DivTimer working-timer project-timer">
                                            {{ getTimerClock }}
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 float-left"  v-if="!state.breakTimerOn">
                                        <button class="btn btn-primary-light font-18  text-uppercase text-white starttimer"
                                            id="btnStart" style="cursor: pointer" data-toggle-tooltip="tooltip"
                                            data-original-title="Start" title="Start" type="submit">{{ isEdit ? 'Update':'Start'}}</button>
                                    </div>
                                    <div class="col-md-12 float-left">
                                        <div class="col-md-6 float-left pl-0">
                                            <a class="btn btn-info py-3 mt-2 font-18 w-100 font-18 text-uppercase text-white d-none"
                                                id="btnPause1" style="cursor: pointer;" title=""
                                                data-toggle-tooltip="tooltip" data-original-title="Pause">Pause</a>
                                        </div>
                                        <div class="col-md-6 float-left pr-0">
                                            <a class="btn btn-danger py-3 font-18 text-uppercase mt-2 w-100 text-white btn-lg stoptimer d-none"
                                                href="javascript:;" title="" id="btnStop1" data-toggle-tooltip="tooltip"
                                                data-original-title="Stop"><span>Stop</span></a>
                                        </div>
                                    </div>
                                    <!-- <div v-if="state.breakTimerOn" class="yourbreak position-absolute col-md-12 p-0">
                                    <div class="yourbreak-in">
                                        <div class="cup"></div>
                                        <div class="col-md-12 text-center mt-2"><h4 class="font-weight-bold">You are on Break</h4></div>
                                        <div class="col-md-12 text-center mt-2">Please click on <span class="text-danger font-weight-bold">"Stop Break"</span> to resume your work.</div>
                                    </div>
                                    <div class="yourbreak-down"></div>
                                </div> -->
                                </div>
                                <div class="col-md-12  choose-break" v-if="!state.breakTimerOn">
                                    <div class="" @click="state.breakTimerOn = !state.breakTimerOn">
                                        <a class="start-break-clr py-3 mt-2 font-18 d-block text-uppercase text-center text-white startbreak"
                                            id="ChooseBreak" title="" style="cursor: pointer" data-toggle-tooltip="tooltip"
                                            data-original-title="Start Break">
                                            <i class="fa fa-coffee pr-2"></i><span>{{$t('Break')}}</span>
                                        </a>
                                    </div>
                                </div>
                                <BreakTime v-if="state.breakTimerOn" v-on:close="breakTimeClose" v-bind:userId="getUserInformation.id" v-bind:trackDataPouse="trackDataPouse">
                                </BreakTime>
                            </div>
                        </form>
                    </div>
                    <!-- Modal Footer -->
                    <div class="modal-footer p-0" id="divModalFootertimetracked">
                        <a href="#" class="btn font-18 d-block w-100 py-3 px-0 m-0 text-uppercase text-white view-timesheet"
                            id="ViewTimesheet" title="" style="cursor: pointer" data-toggle-tooltip="tooltip"
                            data-original-title="View Timesheet">
                            <span>{{ $t('ViewTimesheetDetails') }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div id="DivTimerEntryAttchment" class="modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title ml-5 text-white">Time Track</h4>
                        <input id="AddManuallyHidden" type="hidden" value="Add Manually">
                        <button type="button" data-dismiss="modal" class="close"><i aria-hidden="true"
                                class="fa fa-times"></i></button>
                    </div>
                    <div id="divModalTimetrack" class="modal-body">
                        <form>
                            <div class="row defuse-navbar">
                                <div class="col-md-12 p-0">
                                    <div class="heading-border actionbtn px-1">
                                        <h5 class="h5"><span>{{ $t('ATTACHMENT_PATH') }}</span></h5>
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-10 custom-file pr-0">
                                                <input type="file" name="FileSrc" id="flTimeFile" multiple="multiple"
                                                    class="form-control " tabindex="-1"
                                                    style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                                                <div class="bootstrap-filestyle input-group"><input type="text"
                                                        class="form-control disabled" disabled="">
                                                    <div class="input-group-append" style="cursor:pointer;"><span
                                                            class="group-span-filestyle input-group-btn input-group-text bg-white"
                                                            tabindex="0">
                                                            <label title="Click here to select a file." for="flTimeFile"
                                                                class="mb-0 font-weight-normal ">Choose File</label></span>
                                                        <span class="input-group-text border-0 bg-transparent p-0 pl-2"><a
                                                                href="javascript:;"
                                                                class="round-icon-small btn-danger-light clsattclear"
                                                                title="Clear">
                                                                <i class="fa fa-undo"></i></a><a href="javascript:;"
                                                                class="round-icon-small btn-danger-light clsattremove d-none"
                                                                title="Remove"><i class="fa fa-times">

                                                                </i></a></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 p-0">
                                                <a href="javascript:;" title="" id="btnTimerattachmentSave "
                                                    data-toggle-tooltip="tooltip" data-original-title="Add Attachment"
                                                    class="text-default round-icon-small btn-success-light"
                                                    style="margin-top: 3px;"><i class="fa fa-floppy-o"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="listing col-12">
                                    <div class="row custom-box-width"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="divModalFootertimetracked" class="modal-footer"></div>
                </div>
            </div>
        </div>
        <Loader :isVisible="getLoadingStatus"></Loader>
        <div class="overlay" id="notifymessage" style="" v-if="state.error">
        <div class="alert alert-dismissible fade show msg mw-90 alert-danger" role="alert">
            <span>{{state.error  }}</span>
            <button type="button" class="close p-1" id="closenotifymessage" style="background:none !important;" @click="state.error = ''">
                <span aria-hidden="true"><i class="fa fa-times text-secondary"></i></span>
            </button>
        </div>
    </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from "@vue/composition-api";
import Multiselect from 'vue-multiselect'
import { userTimerInfo } from "../../stores/TimeTracker";
import {   storeToRefs } from "pinia";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import Loader from "../Loader.vue"
import BreakTime from "./BreakTime.vue"
import moment from "moment";

export default {
    components: {
        Multiselect,
        VueTimepicker,
        Loader,
        BreakTime
    },
    props:{
        isEdit:{
    type:Boolean
},
trackDataPouse:{
    type:Object
}
    },
    setup(props,context) {
        const store = userTimerInfo();
        const state = ref({
            menualTime: false,
            breakTimer: "00:00:00",
            breakTimerOn: false,
            textCountertlength: 2000,
            submitted: false,
            startTimerShow: false,
            timer: "00:00:00",
            error:"",
            isEdit:false
        })
        const {
            getProjectDetails,
            getProjectsByUser,
            getTasksFromProject,
            getLoadingStatus,
            getUserInformation,
            getTimerRunResponse,
            getTimerClock,
            timerInfo
        } = storeToRefs(store)
 
        const trackerData = reactive({
            projectId: null,
            trackDescription: "",
            taskId: null,
            isBillable: false,
            format: false,
            date: null,
            trackId:0,
            startTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            stopTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            companyId:null,
            currentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            trackAgent: "web",
            timerStatus: "start",
            CreatedBy:getUserInformation.value.id, 
            linkedTrackId:null,
            TotalHours: 0,
            timeformat: "12",
            TagIds: null,
            isForcedUpdate: null,
            type: "TimeTrack",
            BreakDate: null,
            BreakName: null,
            BreakId: 0,
            BreakStartTime: 0,
            Shift_ID: 0,
            SelectedDate: "",
            Break_Shift:""
        })

        onMounted(async() => {
            //trackerData.trackDescription =""
            state.value.isEdit = props.isEdit
            store.$state.tasks = []
            store.$state.timerRunResponse = ''
            state.value.error = ''
            await store.getProjects()
            if(props.isEdit){
            Object.assign(trackerData, timerInfo.value)
            }
            
            if(timerInfo.value.projectId){   
trackerData.projectId= timerInfo.value.projectId ? getProjectsByUser.value.find((data)=>data.projectId==timerInfo.value.projectId) :''
await store.loadTasks(timerInfo.value.projectId);
trackerData.taskId= timerInfo.value.taskId ? getTasksFromProject.value.find((data)=>data.value==timerInfo.value.taskId) :''
            }
        })


         function  submit()  {
            state.value.submitted = true
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    if(state.value.isEdit){
                        trackerData.timerStatus = "update"
                    }
                    trackerData.projectId = trackerData?.projectId ?trackerData?.projectId?.projectId : null
                    trackerData.taskId = trackerData?.taskId ? trackerData?.taskId?.value : null
                    trackerData.isBillable = trackerData?.isBillable ? 1 : 0
                    trackerData.timeformat = trackerData?.format ? 24 : 12
                    //trackerData.Shift_ID = timerInfo.shift_ID
                    console.log("checkkk",trackerData)
                    await store.submitProjectInfo(trackerData);
                     store.getTimerInfo();
                    state.value.startTimerShow = state.value.startTimerShow;
                    if(getTimerRunResponse.value?.trackId){
                        store.startTimer(0);
                        //breakTimer for timer and breack show in header
                        this.$emit("openTimer",{"breakTimer":false});
                    }else{
                        state.value.error =getTimerRunResponse.value?.userMsg;
                    }
                    
                    // eslint-disable-next-line
                    return;
                }

            });
        }
        // Description text length counter
        function textCounter() {
            state.value.textCountertlength = 2000 - trackerData.trackDescription.length
        }

        function clearAll(props) {
            console.log(props);
        }

        function taskByProject() {
            if (trackerData.projectId) {
                store.loadTasks(trackerData.projectId?.projectId)
            } else {
                //getTasksFromProject = []
                console.log("check", store.$state)
            }
            store.$state.tasks = []
        }
        function breakTimeClose(event){ 
            state.value.breakTimerOn = false
             //breakTimer for timer and breack show in header
            this.$emit("openTimer",event);
        }
        return {
            trackerData,
            submit,
            textCounter,
            clearAll,
            getProjectsByUser,
            taskByProject,
            getTasksFromProject,
            getLoadingStatus,
            state,
            breakTimeClose,
            getTimerClock,
            getUserInformation


        }
    }

}
</script>

