<template>
    <div>
        <li class="nav-item">
            <a class="nav-link timer-icon d-none forceshow" title="" id="btnTimer" data-toggle-tooltip="tooltip" data-original-title="Timer"><i class="fa fa-clock-o m-0" aria-hidden="true"></i><span class="text-lmenu">Timer</span></a>
            <div id="divtimernewdata">
    
                <!--Record container starts-->
    
                <div class="timer_wrap show" id="divTopTimer" v-if="state.breakTimerShow">
                    <div class="timer_desc text-left" style="min-width: 100px;">
                        <div class="desc_txt" id="projectNameTimerTop" :title="state.breakTimerShow"> {{ getTimer.breakName }}</div>
                        <div id="TimerCanves" class="DivTimer timer_txt">{{ breakTimerClock}}</div>
                    </div>
                    <div class="circle stop" @click="stopBreakTime"><a class="pauseTimer" href="javascript:;" title="" id="btnStop" data-toggle-tooltip="tooltip" data-original-title="Stop"><i class="fa fa-stop"></i></a></div>
                </div>
                <div class="timer_wrap show" id="divTopTimer" v-if="!state.breakTimerShow">
                    <div class="timer_desc text-left" style="min-width: 100px;">
                        <div class="desc_txt" id="projectNameTimerTop" :title="getTimer.projectId ? getTimer.projectName : getTimer.trackDescription"> {{ getTimer.projectId ? getTimer.projectName : getTimer.trackDescription }}</div>
                        <div id="TimerCanves" class="DivTimer timer_txt">{{ state.breakTimerShow ? state.breakTimer : getTimerClock }}</div>
                    </div>
                    <div class="circle pause" @click="pauseTimer" v-if="timerPlay"><a class="pausetimer pause" href="javascript:;" id="btnPause" title="" data-toggle-tooltip="tooltip" data-original-title="Pause"><i class="fa fa-pause"></i></a></div>
                    <div class="circle pause" @click="resumeTimer" v-else><a class="pausetimer pause" href="javascript:;" id="btnPause" title="" data-toggle-tooltip="tooltip" data-original-title="Pause"><i class="fa fa-play"></i></a></div>
                    <div class="circle stop" @click="closeTimer"><a class="pauseTimer" href="javascript:;" title="" id="btnStop" data-toggle-tooltip="tooltip" data-original-title="Stop"><i class="fa fa-stop"></i></a></div>
                    <div class="circle" @click="editTimerInfo({modalClose:true})"><a class="updatetimer text-success" href="javascript:;" title="" id="btnTimerUpdate" data-toggle-tooltip="tooltip" data-original-title="Update"><i class="fa fa-pencil"></i></a></div>
                    <!-- <div class="circle"><a class="text-default" href="javascript:;" title="" id="btnTimerattachment" data-toggle-tooltip="tooltip" data-original-title="Add Attachment"><i class="fa fa-upload"></i></a></div> -->
                    <div class="circle pause" @click="breakTimeClose({breakTimer:false,modalClose:true})"><a class="pausetimer pause" href="javascript:;" id="btnPause" title="" data-toggle-tooltip="tooltip" data-original-title="Pause"><i class="fa fa-clock"></i></a></div>
    
                </div>
    
            </div>
        </li>
    
        <div class="overlay" id="notifymessage" style="" v-if="state.error">
            <div class="alert alert-dismissible fade show msg mw-90 alert-danger" role="alert">
                <span>{{state.error  }}</span>
                <button type="button" class="close p-1" id="closenotifymessage" style="background:none !important;" @click="state.error = ''">
                    <span aria-hidden="true"><i class="fa fa-times text-secondary"></i></span>
                </button>
            </div>
        </div>
        <Loader :isVisible="getLoadingStatus"></Loader>
        <TimeTracker v-if="state.showTimetrackerModal" v-on:close="state.showTimetrackerModal=false" v-on:openTimer="editTimerInfo" :start="true" :isEdit="true" v-bind:trackDataPouse="trackerData"></TimeTracker>
        <!-- Break time call from timer module then alsways timerModule=true -->
        <BreakTime v-if="state.breakTimerOn" v-on:close="breakTimeClose" v-bind:userId="getUserInformation.id" v-bind:timerModule="true" v-bind:trackDataPouse="trackerData">
        </BreakTime>
    </div>
    </template>
    
    <script>
    import {
        defineComponent,
        onMounted,
        onUnmounted,
        ref,
        reactive
    } from '@vue/composition-api'
    import {
        userTimerInfo
    } from "../../stores/TimeTracker";
    import Loader from "../Loader.vue"
    import {
        storeToRefs
    } from "pinia";
    import moment from "moment"
    import {
        formatter,
        getSecondsFromDate,
        trackerDataFormCommen,
        timeToSeconds
    } from "@/assets/common/vue-common.js"
    import TimeTracker from "./TimeTrackerModal.vue";
    import BreakTime from "./BreakTime.vue"
    export default defineComponent({
        components: {
            Loader,
            TimeTracker,
            BreakTime
        },
        props:{
            breakOrTimerOn:{
                type:Object
            }
        },
        setup(props, context) {
            
            const store = userTimerInfo();
            const {
                getTimer,
                getLoadingStatus,
                getUserInformation,
                getTimerClock,
                getTimerRunResponse,
                breakTimerClock
            } = storeToRefs(store)
            const state = ref({
                breakTimerShow: false,
                breakTimerPlay: false,
                breakTimerInterval: '',
                breakTimeCount: 0,
                breakTimer: "00:00:00",
                showTimetrackerModal: false,
                breakTimerOn: false,
                error:''
    
            })
            const timerPlay = ref(true)
            const trackerData = reactive({
                projectId: null,
                trackDescription: "",
                taskId: null,
                isBillable: false,
                format: false,
                date: null,
                trackId: 0,
                dateStart: '',
                startTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                stopTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                companyId: null,
                currentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                trackAgent: "web",
                timerStatus: "",
                CreatedBy: getUserInformation.value.id,
                createdBy: getUserInformation.value.id,
                linkedTrackId: null,
                totalHours: 0,
                timeformat: "12",
                TagIds: null,
                isForcedUpdate: null,
                type: "TimeTrack",
                BreakDate:  moment().format("YYYY-MM-DD HH:mm:ss"),
                BreakName: null,
                BreakId: 0,
                BreakStartTime: 0,
                BreakStopTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                Shift_ID: 0,
                SelectedDate: "",
                Break_Shift: "",
            })
            onMounted(() => {
                if(props.breakOrTimerOn){
                state.value.breakTimerShow = props.breakOrTimerOn.breakTimer
                state.value.breakTimerPlay = props.breakOrTimerOn.breakTimer
                }
                store.$state.timerCountInSec = 0
                store.setTimerClock(store.$state.timerCountInSec);
                //Check break timer and timer start or not if start then start timer
                if (getTimer.value?.statusCode) {
                    if (getTimer.value?.trackType == 'Break') {
                        store.$state.breakNegative = true
                        let seconds = getSecondsFromDate({
                            startDate: getTimer.value?.dateStart,
                            endDate: moment()
                        }, 'seconds')
                        
                        store.$state.breakTimerCountInSec = timeToSeconds(getTimer.value?.breakDuration) -seconds
                        startBreakTime()
                    } else {
                        let countResumeInSeconds = 0
                        store.$state.timerCountInSec = getTimer.value?.dateStop != "01-01-1950 00:00:00" ?
                            countResumeInSeconds + parseInt(getTimer.value.resumeHours) :
                            getSecondsFromDate({
                                startDate: getTimer.value?.dateStart,
                                endDate: moment()
                            }, 'seconds')
                        Object.assign(trackerData, getTimer.value)
                        if (getTimer.value?.statusCode != "ACT") {
                            store.setTimerClock(store.$state.timerCountInSec);
                            trackerData.totalHours = store.$state.timerCountInSec
                            timerPlay.value = false
                        } else {
                            startTimer();
    
                        }
                    }
                }
            })
    
            function startTimer() {
                store.startTimer(store.$state.timerCountInSec)
                timerPlay.value = true
            }
    
            function resumeTimer() {
                trackerData.startTime = moment().format('YYYY-MM-DD HH:mm:ss')
                // trackerData.dateStart = moment().format('YYYY-MM-DD HH:mm:ss')
                submitTime({
                    timer: "resume"
                });
                startTimer();
            }
    
            function pauseTimer() {
                trackerData.stopTime = moment().format('YYYY-MM-DD HH:mm:ss')
                // trackerData.dateStop = moment().format('YYYY-MM-DD HH:mm:ss')
                trackerData.totalHours = store.$state.timerCountInSec
                submitTime({
                    timer: "pause"
                })
                timerPlay.value = false
                store.stopTimer()
            }
            /* Timer Stop and close timer components 
             */
            function closeTimer() {
                /* Clear Interval from store */
                store.stopTimer()
                store.$state.timerCountInSec = 0
                trackerData.stopTime = moment().format('YYYY-MM-DD HH:mm:ss')
                trackerData.totalHours = store.$state.timerCountInSec
                submitTime({
                    timer: "stop"
                })
                store.setTimerClock("00:00:00")
                this.$emit("startTimerClose", {
                    isOpen: false,
                    update: false
                })
            }
    
          async  function submitTime(event) {
                trackerData.CreatedBy = getUserInformation.value.id
                trackerData.createdBy = getUserInformation.value.id
                trackerData.trackId =  getTimer.value?.trackId
                trackerData.trackDescription = getTimer.value?.trackDescription
                trackerData.timerStatus = event.timer,
    
                await store.submitProjectInfo(trackerData)
                await store.getTimerInfo();
    
                //store.submitTimer(info);
            }
    
            onUnmounted(() => {
                // submitTime(true)
    
            })
            /* Start break time */
    
            function startBreakTime() {
                state.value.breakTimerShow = true
                state.value.breakTimerPlay = true
                store.breakStartTimer()
            }
           async function stopBreakTime() {
                trackerData.CreatedBy = getUserInformation.value.id
                trackerData.createdBy = getUserInformation.value.id
                trackerData.trackId = getTimerRunResponse.value ? getTimerRunResponse.value.trackId : getTimer.value?.trackId
                trackerData.trackDescription = getTimer.value?.trackDescription
                trackerData.timerStatus = "stop",
                trackerData.type = "Break"
                trackerData.BreakStartTime =getTimer.value.dateStart
                trackerData.BreakStopTime = moment().format('YYYY-MM-DD HH:mm:ss')
                let seconds = getSecondsFromDate({
                            startDate: getTimer.value?.dateStart,
                            endDate: moment()
                        }, 'seconds')
                trackerData.totalHours = seconds
                store.breakStopTimer()
                state.value.breakTimerShow = false
                
                await store.submitProjectInfo(trackerData)
                store.$state.breakTimerCountInSec = 0
                store.$state.timerRunResponse = ''
                await store.getTimerInfo();
                if (getTimer.value?.statusId==1001) {
                store.$state.timerCountInSec = parseInt(getTimer.value.resumeHours)
                trackerData.type = "TimeTrack"
                resumeTimer()
                }
                
    
            }
    
            // function resumeBreakTime() {
    
            //     startBreakTime()
    
            // }
    
            // function pauseBreakTime() {
            //     state.value.breakTimerPlay = false
            //     clearInterval(state.value.breakTimerInterval)
    
            // }
    
            /* End break time */
            function editTimerInfo(event) {
                state.value.showTimetrackerModal = event.modalClose
                if(event.breakTimer){
                    state.value.breakTimerShow = true
                }
            }
    
            function breakTimeClose(event) {
                state.value.breakTimerShow = event.breakTimer
                state.value.breakTimerOn = event.modalClose
            }
            return {
                closeTimer,
                pauseTimer,
                startTimer,
                timerPlay,
                getTimer,
                getLoadingStatus,
                state,
                resumeTimer,
                startBreakTime,
                stopBreakTime,
                //resumeBreakTime,
                //pauseBreakTime,
                getTimerClock,
                editTimerInfo,
                breakTimerClock,
                breakTimeClose,
                getUserInformation,
                trackerData
    
            }
        },
    })
    </script>
    
    <style scoped>
    .select2-container {
        z-index: 99999 !important;
    }
    
    .timetrackpadding {
        padding: 0 2px 0 10px;
    }
    </style>
    