import { defineStore } from "pinia";
import { useloggedUserInfo } from "./NavUserInfo"
import  DataService   from "../services/DataService";
import {
    formatter,
} from "@/assets/common/vue-common.js"
export const userTimerInfo = defineStore("TimeTracker", {
    state: () => ({
        timerCountInSec: 0,
        breakTimerCountInSec:0,
        timerInfo: '',
        timerProjectDetails:"",
        projects:[],
        tasks:[],
        breakTimerList:[],
        loading:false,
        timerRunResponse:'',
        timerClock:"00:00:00",
        breakTimerClock:"00:00:00",
        interval:"",
        breakInterval:"",
        breakNegative:false
    }),
    getters: {
        getTimer: (state) => {
            return state.timerInfo
        },
        getProjectDetails:(state)=>{
            return state.timerProjectDetails
        },
        getProjectsByUser:(state)=>{
            return state.projects
        },
        getTasksFromProject:(state)=>{
            return state.tasks
        },
        getLoadingStatus:(state) =>{
            return state.loading
        },
        getBreakTimeList:(state)=>{
            return  state.breakTimerList;
        },
        getUserInformation:()=>{
         const  {user} = useloggedUserInfo();
         return user.profile;
        },
        getTimerRunResponse:(state)=>{
            return state.timerRunResponse
        },
        getTimerClock:(state)=>{
            return state.timerClock
        },
        getBreakTimerClock:(state)=>{
            return state.breakTimerClock
        },
    },
    actions: {
        async submitProjectInfo(info) {
           // let param = 'projectId='+info?.projectId+'&trackDescription='+info?.trackDescription+'&taskId='+info?.taskId+'&isBillable=0&startTime='+info.startTime+'&stopTime='+info.stopTime+'&currentDate='+info.currentDate+'&timeformat='+info.timeformat+'&type='+info.type;
            this.loading = true
            try{
                const {data} =await DataService.SaveProjectInfo(info);
               this.timerRunResponse = data
            }catch(e){

            }
            this.loading = false
        },
        async getProjects() {
            this.loading =true
            try{
                const { data}= await DataService.GetTimerProjectsByUserID();
                
                this.projects = typeof data?.res =="object" ? data?.res: [];

               
            }catch(e){

            }
            this.loading = false
        },
        async loadTasks(id) {
            this.loading = true 
            try{
                const { data}= await DataService.LoadTasks(id);
                this.tasks = data
                //console.log("checkk",data?.res)
            }catch(e){

            }
            this.loading = false 
        },
        async getTimerInfo(){
            this.loading = true 
            try{
           const {data} =  await DataService.GetIncompleteEntry();
             this.timerInfo = data?.result[0]
            }catch(error){
                console.log('error',error);
            }
            this.loading = false 
            
        },
        async getBreakTime(){
            this.loading = true
            try{
                const {data} = await DataService.GetBreakTimeList();
                data?.result?.map((item)=>{
                    this.breakTimerList.push({
                        name:item.breakDetail,
                        value:item.breakId,
                        breakDuration:item.breakDuration
                    })
                 })
            }catch(e){

            }
            this.loading = false
        },
        setTimerClock(sec){
            this.timerClock= formatter(sec)
        },
        startTimer(){
            clearInterval(this.interval);
            clearInterval(this.breakInterval);
           this.interval = setInterval(() => {
            this.timerCountInSec++
            this.setTimerClock(this.timerCountInSec);
        }, 1000);
        },
        stopTimer(){
            clearInterval(this.interval);
        },
        breakSetTimerClock(sec){
            this.breakTimerClock= formatter(sec)
        },
        breakStartTimer(){
            clearInterval(this.breakInterval);
            clearInterval(this.interval);
           this.breakInterval = setInterval(() => {
                this.breakTimerCountInSec--
                this.breakSetTimerClock(this.breakTimerCountInSec,'positive')
        }, 1000);
        },
        breakStopTimer(){
            clearInterval(this.breakInterval);
        }
    },
   // persist: true,
})